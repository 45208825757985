import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import BlogCard from "../components/blog/blogCard"
import PageNumber from "../components/blog/pageNumber"
import Seo from "../components/Seo"
import HeroBlog from "../components/blog/hero"
import Navbar from "../components/blog/navbar"
import Search from "../assets/img/blog/Search.svg"
import SearchBar from "../components/blog/searchBar"
import Layout from "../components/Layout"
import MissingSearch from "../components/blog/missingSearch"

const BlogList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Work Sans;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  /* background: #fbfbfb; */

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .resize {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

const ArticleContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5% 5%;
  margin-top: 5%;
  padding: 0 5%;
`
const SearchStyle = styled.div`
  margin-top: 32px;
  width: 80%;
  display: flex;
  justify-content: center;

  border-radius: 0;

  input {
    appearance: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    max-width: 480px;
    padding: 4px 8px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    :focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      outline: none;
    }
  }
  .box {
    width: 36px;
    height: 36px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    cursor: pointer;
  }
`

export default function Blog({ data, pageContext }) {
  console.log(data)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [categoryType, setCategoryType] = useState([])

  let blogNumber = 0
  // let categoryType = []

  useEffect(() => {
    let params = new URLSearchParams(document.location.search)
    let page = params.get("page")
    if (page) setPage(page)
  }, [])

  return (
    <Layout>
      <BlogList>
        <Seo
          title={"Guides, Tutorials, News: LicenseSpring Blog"}
          description="Helpful how-tos & step-by-step guides for our most popular PDF tools, and everything else PDF."
        />
        <HeroBlog />
        <Navbar categories={categoryType} />
        <div className="resize">
          <SearchBar setPage={setPage} setSearch={setSearch} />
          <ArticleContainer>
            {data.allPrismicLicensespringPost.nodes.map((post, i) => {
              let author = post.data.author.document?.data.name
                ? post.data.author.document?.data.name
                : {
                    post: {
                      data: {
                        author: { document: { data: { name: "anonymous" } } },
                      },
                    },
                  }

              if (
                !categoryType.includes(post.data.category.toLocaleLowerCase())
              )
                categoryType.push(post.data.category.toLocaleLowerCase())
              //trazilica
              if (
                post.data.title.toLowerCase().includes(search.toLowerCase()) ||
                post.data.body.text.toLowerCase().includes(search.toLowerCase())
              ) {
                blogNumber += 1
                return (
                  <BlogCard
                    post={post}
                    author={author ? author : "anonymous"}
                    visible={
                      blogNumber - 1 <= 8 * (page - 1) - 1 ||
                      blogNumber - 1 > 8 * page - 1
                        ? "none"
                        : "flex"
                    }
                  />
                )
              }
            })}
            {blogNumber == 0 && <MissingSearch />}
          </ArticleContainer>
          <PageNumber
            location="/blog"
            blogNumber={blogNumber - 1}
            setPage={setPage}
            page={page}
          />
        </div>
      </BlogList>
    </Layout>
  )
}
//https://momentjs.com/docs/#/displaying/format/
export const pageQuery = graphql`
  query MyQuery {
    allPrismicLicensespringPost(
      sort: { fields: data___publish_date, order: DESC }
    ) {
      nodes {
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
            alt
          }
          body {
            text
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`
