import React from "react"
import styled from "styled-components"

const MissingSearchStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  h3 {
    font-size: 24px;
    margin: 24px 0;
    font-weight: 500;
    color: rgb(17, 17, 17);
    font-family: DM Sans, sans-serif;
    text-align: center;
  }
  .box {
    width: 200px;
    height: 200px;

    svg {
      fill: #13b0fc;
      stroke: #13b0fc;
    }
  }
`

function MissingSearch() {
  return (
    <MissingSearchStyle>
      <h3>
        There is no relevant blog posts, please search something else or switch
        category...
      </h3>
    </MissingSearchStyle>
  )
}

export default MissingSearch
